export const AppBarStyles = {
  position: 'fixed',
  component: 'nav',
  boxShadow: 'none',
  borderBottom: '1px solid #0C0D0D',
  height: '60px',
  justifyContent: 'center',
};
  
export default [
  AppBarStyles
];
